import React, { useEffect, useState } from "react";
import ReactFlow, {
  Node,
  Edge,
  useStore,
  useReactFlow,
  Controls,
} from "reactflow";
import { Connections } from "../pages/Participant";
import { Col, Row } from "react-bootstrap";

interface Graph {
  nodes: Node[];
  edges: Edge[];
  rows: number;
}

const ConnectionsMap: React.FC<{ connections: Connections }> = ({
  connections,
}) => {
  const reactFlow = useReactFlow();
  const widthSelector = (state: { width: any }) => state.width;
  const heightSelector = (state: { height: any }) => state.height;
  const reactFlowWidth = useStore(widthSelector);
  const reactFlowHeight = useStore(heightSelector);
  const [graph, setGraph] = useState<Graph>({ nodes: [], edges: [], rows: 0 });
  const [legend, setLegend] = useState<Map<string, string>>(new Map());

  // hue step between colors, wrapping at 360 is done when generating a new color
  const hueStep = (360 / 10) * 2.7;

  useEffect(() => {
    const buildGraph = () => {
      // Calculate the positions for the other nodes

      const typeToColorMap = new Map<string, string>();

      const getNextColor = (): string => {
        const hue = (typeToColorMap.size * hueStep) % 360;
        return `hsl(${hue}, 40%, 80%)`;
      };

      const getNodeColor = (type: string): string => {
        // Check if the color for this type is already assigned
        if (typeToColorMap.has(type)) {
          return typeToColorMap.get(type)!; // The '!' operator tells TypeScript the value is non-null
        }

        // If not, generate a new color and save it
        const newColor = getNextColor();
        typeToColorMap.set(type, newColor);
        return newColor;
      };

      const width = 80;
      const height = 20;
      const xStep = 120;
      const yStep = 60;
      const maxNodesPerRow = 4;

      const meNode: Node = {
        id: "me",
        data: { label: "Me" },
        position: { x: 0, y: 0 },
        type: "input",
        style: {
          border: "2px solid #222",
          padding: "10px",
          width: width,
          height: height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
        },
        draggable: false,
      };

      const nodeCount = Object.keys(connections).length;
      const rowCount = Math.ceil(nodeCount / maxNodesPerRow);

      // Connections
      const otherNodes: Node[] = Object.entries(connections).map(
        ([personName, personProperties], index) => {
          const rowIndex = Math.floor(index / maxNodesPerRow);
          const columnIndex = index % maxNodesPerRow;

          const nodesInCurrentRow = Math.min(
            maxNodesPerRow,
            nodeCount - rowIndex * maxNodesPerRow,
          );
          const x0 = (-nodesInCurrentRow * xStep) / 2 + xStep / 2;

          const x = x0 + columnIndex * xStep;
          const y = yStep * (rowIndex + 1);

          return {
            id: personName,
            data: { label: personName },
            position: { x, y },
            type: "output",
            style: {
              background: getNodeColor(personProperties.type),
              border: "1px solid #ddd",
              padding: "10px",
              width: width,
              height: height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            draggable: false,
          };
        },
      );

      const updatedEdges: Edge[] = Object.entries(connections).map(
        ([personName, personProperties], index) => {
          return {
            id: `me->${personName}`,
            source: "me",
            target: personName,
            // label: `${personProperties.weeks.length}`,
            style: {
              strokeWidth: personProperties.weeks.length,
            },
          };
        },
      );

      const updatedNodes = [meNode, ...otherNodes];

      setGraph({
        nodes: updatedNodes,
        edges: updatedEdges,
        rows: rowCount,
      });

      setLegend(typeToColorMap);
    };

    buildGraph();
  }, [connections]);

  useEffect(() => {
    reactFlow.fitView();
  }, [reactFlowWidth, reactFlowHeight, reactFlow]);

  return (
    <>
      <Row
        style={{
          width: "100%",
          height: `${100 * (graph.rows + 1)}px`,
          minHeight: "300px",
        }}
      >
        <ReactFlow
          nodes={graph.nodes}
          edges={graph.edges}
          nodesConnectable={false}
          elementsSelectable={false}
          zoomOnScroll={false}
          fitView
        >
          <Controls />
        </ReactFlow>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // remove this line if you don't want to vertically center the columns
        }}
        className="mt-3"
      >
        {Array.from(legend).map(([type, color]) => {
          return (
            <Col
              xs={3}
              sm={2}
              key={type}
              style={{
                alignItems: "center",
              }}
              className="mb-1"
            >
              <div
                style={{
                  width: "100%",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: color,
                  marginRight: "5px",
                  marginLeft: "5px",
                  textAlign: "center",
                  border: "1px solid #ddd",
                }}
              >
                {type}
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ConnectionsMap;
