// StudyList.tsx
import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, Col, Row } from "react-bootstrap";
import { EntryStatus } from "../pages/Participant";

const EntryList: React.FC<{
  entries: EntryStatus;
  study: string;
  participant: string;
}> = ({ entries, study, participant }) => {
  // Fetch entries for the participant

  return (
    <Row className="justify-content-md-center">
      {Object.entries(entries).map(([key, weekStatus]) => {
        const weekNumber = key;
        return (
          <Col key={weekNumber} md={2} xs={3} className="mb-2">
            <ListGroup.Item action as="div" className="w-100">
              <Link
                to={
                  weekStatus.exists
                    ? `/entries/${study}/${participant}/${weekNumber}`
                    : `/entries/${study}/${participant}/${weekNumber}/new`
                }
                className={`btn ${
                  weekStatus.exists
                    ? "btn-outline-primary"
                    : "btn-outline-secondary"
                } btn-block w-100`}
              >
                Week {weekNumber}
              </Link>
            </ListGroup.Item>
          </Col>
        );
      })}
    </Row>
  );
};

export default EntryList;
