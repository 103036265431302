import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import StudyList from "./pages/StudyList";
import StudyForm from "./pages/StudyForm";
import NotFoundPage from "./404Page";
import EntryForm from "./pages/EntryForm";
import ParticipantDashboard from "./pages/Participant";

import { FiefAuthProvider } from "@fief/fief/react";

import Callback from "./components/Callback";
import RequireAuth from "./components/RequireAuth";

import "reactflow/dist/style.css";
import { config } from "./utils/config";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <div className="app-container">
      <FiefAuthProvider baseURL={config.fiefUrl} clientId={config.fiefClientId}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Navigate to="/studies" replace />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/studies"
              element={
                <RequireAuth>
                  <StudyList />
                </RequireAuth>
              }
            />
            <Route
              path="/studies/new"
              element={
                <RequireAuth>
                  <StudyForm />
                </RequireAuth>
              }
            />
            <Route
              path="/studies/:studyId"
              element={
                <RequireAuth>
                  <StudyForm />
                </RequireAuth>
              }
            />
            <Route
              path="/studies/:studyId/edit"
              element={
                <RequireAuth>
                  <StudyForm />
                </RequireAuth>
              }
            />
            <Route
              path="/entries/:study/:participant/"
              element={
                <RequireAuth>
                  <ParticipantDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/entries/:study/:participant/:week"
              element={
                <RequireAuth>
                  <EntryForm />
                </RequireAuth>
              }
            />
            <Route
              path="/entries/:study/:participant/:week/new"
              element={
                <RequireAuth>
                  <EntryForm />
                </RequireAuth>
              }
            />
            <Route
              path="/entries/:study/:participant/:week/edit"
              element={
                <RequireAuth>
                  <EntryForm />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </Router>
      </FiefAuthProvider>
    </div>
  );
}

export default App;
