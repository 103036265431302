// StudyList.tsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Row, Col, Container } from "react-bootstrap";
import { apiClient } from "../services/api";
import { components } from "../services/apiService";
import NotFoundPage from "../404Page";
import EntryList from "../components/EntryList";
import ConnectionsMap from "../components/ConnectionsMap";
import { ReactFlowProvider } from "reactflow";
import { Form } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import { useFiefTokenInfo } from "@fief/fief/build/esm/react";

export type Connections = {
  [key: string]: components["schemas"]["ConnectionProperties"];
};

export type EntryStatus = {
  [key: string]: components["schemas"]["WeekStatus"];
};

type SliderMarks = {
  value: number;
  label: string;
};

const ParticipantDashboard: React.FC = () => {
  const { study } = useParams<"study">();
  const { participant } = useParams<"participant">();
  const [numWeeks, setNumWeeks] = useState(1);
  const [sliderMarks, setSliderMarks] = useState<SliderMarks[]>([]);
  const [week, setWeek] = useState(1);
  const [connections, setConnections] = useState<Connections>({});
  const [filteredConnections, setFilteredConnections] = useState<Connections>(
    {},
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [entries, setEntries] = useState<EntryStatus>();
  const tokenInfo = useFiefTokenInfo();

  useEffect(() => {
    function findLargestKeyWithExistsTrue(data: EntryStatus) {
      // Convert object to array of entries, filter to only those with 'exists' true,
      // and map to just the keys as integers
      const keysWithExistsTrue = Object.entries(data)
        .filter(([key, value]) => value.exists)
        .map(([key]) => parseInt(key, 10));

      // Find the maximum key
      const maxKey = Math.max(...keysWithExistsTrue);

      // Check for case when there are no true 'exists', Math.max([]) gives -Infinity
      return isFinite(maxKey) ? maxKey : null;
    }

    const fetchEntries = async () => {
      if (study && participant) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/entries/{study}/{participant}",
            {
              params: { path: { study: study, participant: participant } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setEntries(data);
            setWeek(findLargestKeyWithExistsTrue(data)!);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load entries for study with id '${study}' and participant '${participant}'`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching the data");
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchConnections = async () => {
      if (study && participant) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/connections/{study}/{participant}",
            {
              params: { path: { study: study, participant: participant } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setConnections(data);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load connections for study with id '${study}' and participant '${participant}'`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching connections");
        }
      }
    };

    fetchConnections();
    fetchEntries();
  }, [study, participant, tokenInfo?.access_token]);

  useEffect(() => {
    // Fetch study parameters
    const fetchStudy = async () => {
      if (study) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/studies/{study}",
            {
              params: { path: { study: study } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setNumWeeks(data.weeks);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load study with id '${study}'`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching the study");
        }
      }
    };
    fetchStudy();
  }, [study, tokenInfo?.access_token]);

  useEffect(() => {
    const filterConnectionsByWeek = () => {
      const filtConnections: Connections = {};
      Object.entries(connections).forEach(([key, value]) => {
        const filteredWeeks = value.weeks.filter(
          (weekNumber) => weekNumber <= week,
        );
        if (filteredWeeks.length > 0) {
          const newValue = { ...value, weeks: filteredWeeks };
          filtConnections[key] = newValue;
        }
      });
      setFilteredConnections(filtConnections);
    };
    filterConnectionsByWeek();
    setLoading(false);
  }, [week, connections]);

  useEffect(() => {
    const buildSliderMarks = () => {
      const newSliderMarks: SliderMarks[] = [];
      for (let week = 1; week <= numWeeks; week++) {
        newSliderMarks.push({ value: week, label: `Week ${week}` });
      }
      setSliderMarks(newSliderMarks);
    };
    buildSliderMarks();
  }, [numWeeks]);

  if (!(study && participant)) {
    return <NotFoundPage />;
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col lg="10">
          <div>
            <Row>
              {error && (
                <Alert
                  variant="danger"
                  onClose={() => setError(null)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{error}</p>
                </Alert>
              )}

              {loading && (
                <Alert variant="info">
                  <Alert.Heading>Loading</Alert.Heading>
                  <p>Please wait...</p>
                </Alert>
              )}

              {loading || error || (
                <>
                  <ReactFlowProvider>
                    <ConnectionsMap connections={filteredConnections} />
                  </ReactFlowProvider>

                  <Form.Group as={Row} className="align-items-center mt-5">
                    <Slider
                      value={week}
                      onChange={(event, newValue) => setWeek(Number(newValue))}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={numWeeks}
                      marks={sliderMarks}
                    />
                  </Form.Group>
                </>
              )}
            </Row>
            <Row className="mt-5">
              <Row>
                {entries && (
                  <EntryList
                    entries={entries}
                    study={study}
                    participant={participant}
                  />
                )}
              </Row>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ParticipantDashboard;
