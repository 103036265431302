import React from "react";
import { Container, Alert } from "react-bootstrap";

const NotFoundPage: React.FC = () => {
  return (
    <Container className="mt-5">
      <Alert variant="danger">
        <Alert.Heading>404 - Page Not Found!</Alert.Heading>
        <p>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
      </Alert>
    </Container>
  );
};

export default NotFoundPage;
