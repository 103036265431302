// StudyForm.tsx
import React, { useState, FormEvent, useEffect } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { components } from "../services/apiService";
import { apiClient } from "../services/api";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  InputGroup,
  Alert,
} from "react-bootstrap";
import DeleteButtonComponent from "../components/DeleteButton";
import NotFoundPage from "../404Page";
import { useFiefTokenInfo } from "@fief/fief/build/esm/react";

const StudyForm: React.FC = () => {
  const { studyId } = useParams<"studyId">(); // Specify the expected param keys
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const tokenInfo = useFiefTokenInfo();
  const [newlyAddedQuestions, setNewlyAddedQuestions] = useState<number[]>([]);

  // Boolean to determine if we are in edit mode.
  const isEditMode = location.pathname.endsWith("/edit");
  const isNewMode = location.pathname.endsWith("/new");

  // Define state for the form fields
  const [formData, setFormData] = useState<
    components["schemas"]["StudyContent"]
  >({
    name: "",
    description: "",
    weeks: 6,
    manager: "",
    participants: [],
    questions: [],
  });

  // Fetch study details when component mounts or studyId changes
  useEffect(() => {
    const fetchStudy = async () => {
      if (studyId) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/studies/{study}",
            {
              params: { path: { study: studyId } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setFormData(data);
            setNewlyAddedQuestions([]);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load study with id: ${studyId}`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching the data");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchStudy();
  }, [studyId, tokenInfo?.access_token]);

  const handleDelete = async (itemId: string) => {
    try {
      const { error, response } = await apiClient.DELETE("/studies/{study}", {
        params: { path: { study: itemId } },
        headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
      });
      if (error) {
        setError(
          `${response.statusText}: Failed to delete study: ${formData.name}`,
        );
      } else {
        navigate("/studies");
      }
    } catch (err) {
      setError(`An error occurred while deleting study:  ${formData.name}`);
    }
  };

  // Handle form field changes
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle adding new participant
  const addParticipant = () => {
    setFormData({
      ...formData,
      participants: [...formData.participants, ""],
    });
  };

  // Function to handle adding new question
  const addQuestion = () => {
    setNewlyAddedQuestions([...newlyAddedQuestions, formData.questions.length]);
    setFormData({
      ...formData,
      questions: [
        ...formData.questions,
        { prompt: "", hint: "", type: "text" },
      ],
    });
  };

  // Function to handle participant change
  const handleParticipantChange = (
    index: number,
    event: React.ChangeEvent<HTMLElement>,
  ) => {
    const target = event.target as HTMLInputElement; // Cast the target to be more specific
    const updatedParticipants = formData.participants.map((name, i) =>
      index === i ? target.value : name,
    );

    setFormData({ ...formData, participants: updatedParticipants });
  };

  // Function to handle question change
  const handleQuestionChange = (
    index: number,
    field: string,
    event: React.ChangeEvent<HTMLElement>,
  ) => {
    const target = event.target as HTMLInputElement; // Cast the target to be more specific

    let question = formData.questions[index]!;
    question[field] = target.value;

    const updatedQuestions = formData.questions.map((q, i) =>
      index === i ? question : q,
    );

    setFormData({ ...formData, questions: updatedQuestions });
  };

  // Function to remove specific participant
  const removeParticipant = (index: number) => {
    const filteredParticipants = formData.participants.filter(
      (_, i) => i !== index,
    );
    setFormData({ ...formData, participants: filteredParticipants });
  };

  // Get the navigate function from the useNavigate hook
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const { data, error, response } = studyId
        ? await apiClient.PUT("/studies/{study}", {
            params: { path: { study: studyId } },
            body: formData,
            headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
          })
        : await apiClient.POST("/studies/", {
            body: formData,
            headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
          });
      if (data) {
        navigate(`/studies/${data.study._id}`);
      } else if (error) {
        const errorString = studyId
          ? `${response.statusText}: Failed to save study: ${formData.name}`
          : `${response.statusText}: Failed to create study: ${formData.name}`;
        setError(errorString);
        let newErrorDetails: string[] = [];

        if (response.status === 422 && typeof error.detail === "object") {
          for (let detail of error.detail) {
            if (detail.loc.length) {
              const detailString = `Field '${detail.loc.join(".")}': ${
                detail.msg
              }`;
              newErrorDetails.push(detailString);
            }
          }
        } else if (
          response.status === 409 &&
          typeof error.detail === "string"
        ) {
          newErrorDetails.push(error.detail);
        }
        setErrorDetails(newErrorDetails);
      }
    } catch (err) {
      const errorString = studyId
        ? `An error occurred while saving study: ${formData.name}`
        : `An error occurred while creating study: ${formData.name}`;
      setError(errorString);
    }
  };

  if (!isNewMode && !studyId) {
    return <NotFoundPage />;
  }

  return (
    <Container className="mt-3 mb-3">
      <Row className="justify-content-md-center">
        <Col lg="8">
          {error ? (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{error}</p>
              {errorDetails.length > 0 && (
                <ul>
                  {errorDetails.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              )}
            </Alert>
          ) : (
            <>
              {isNewMode ||
                (loading && (
                  <Alert variant="info">
                    <Alert.Heading>Loading</Alert.Heading>
                    <p>Please wait...</p>
                  </Alert>
                ))}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Row className="justify-content-md-center">
                    <h1 style={{ textAlign: "center" }}>Study</h1>
                  </Row>
                  <Form.Group controlId="name">
                    <InputGroup>
                      <InputGroup.Text>Name</InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Study name"
                        required
                        disabled={!isNewMode}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="manager">
                    <InputGroup>
                      <InputGroup.Text>Manager</InputGroup.Text>
                      <Form.Control
                        type="email"
                        name="manager"
                        required
                        value={formData.manager}
                        onChange={handleChange}
                        placeholder="Manager's email address"
                        disabled={!isEditMode && !isNewMode}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="weeks">
                    <InputGroup>
                      <InputGroup.Text>Weeks</InputGroup.Text>
                      <Form.Control
                        type="number"
                        name="weeks"
                        min="1"
                        value={formData.weeks}
                        onChange={handleChange}
                        disabled={!isEditMode && !isNewMode}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="description" className="mt-1 mb-1">
                    <Form.Control
                      as="textarea"
                      name="description"
                      required
                      placeholder="Short description of the study"
                      value={formData.description}
                      onChange={handleChange}
                      disabled={!isEditMode && !isNewMode}
                    />
                  </Form.Group>

                  {formData.questions.map((question, index) => (
                    <Row key={`question-${index}`}>
                      <Row className="justify-content-md-center mt-3">
                        <h3 style={{ textAlign: "center" }}>
                          Question {index + 1}
                        </h3>
                      </Row>
                      <Form.Group
                        className="mb-1"
                        controlId={`question-${index}-type`}
                      >
                        <InputGroup>
                          <InputGroup.Text>Question type</InputGroup.Text>
                          <Form.Select
                            value={question.type}
                            disabled={
                              !(
                                isNewMode ||
                                (isEditMode &&
                                  newlyAddedQuestions.includes(index))
                              )
                            }
                            onChange={(e) =>
                              handleQuestionChange(index, "type", e)
                            }
                          >
                            <option value="text">Text</option>
                            <option value="text_handle">Text Handle</option>
                            <option value="slider">Slider</option>
                            <option value="duration">Duration</option>
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        className="mb-1"
                        controlId={`question-${index}-prompt`}
                      >
                        <Form.Label>
                          <strong>Question prompt</strong>
                        </Form.Label>
                        <Form.Control
                          placeholder="Describe when, where, and who you met."
                          type="string"
                          as="textarea"
                          required
                          value={question.prompt}
                          onChange={(e) =>
                            handleQuestionChange(index, "prompt", e)
                          }
                          disabled={!isEditMode && !isNewMode}
                        />
                      </Form.Group>
                      {question.type !== "duration" && (
                        <Form.Group
                          className="mb-1"
                          controlId={`question-${index}-hint`}
                        >
                          <Form.Label>
                            <strong>
                              {question.type === "slider"
                                ? "Values"
                                : "Answer hint"}
                            </strong>
                          </Form.Label>
                          <Form.Control
                            placeholder={
                              question.type === "slider"
                                ? "Values to show on the sides of the slider. E.g. Not at all:Very much."
                                : "A few paragraphs describing the interaction."
                            }
                            type="string"
                            value={question.hint}
                            onChange={(e) =>
                              handleQuestionChange(index, "hint", e)
                            }
                            disabled={!isEditMode && !isNewMode}
                          />
                        </Form.Group>
                      )}
                    </Row>
                  ))}
                  {(isEditMode || isNewMode) && (
                    <Col xs="6" className="mt-2">
                      <Button
                        className="mb-3"
                        variant="outline-secondary"
                        onClick={addQuestion}
                      >
                        Add question
                      </Button>
                    </Col>
                  )}

                  <Row className="justify-content-md-center mt-3">
                    <h3 style={{ textAlign: "center" }}>Participants</h3>
                  </Row>
                  {formData.participants.map((participant, index) => (
                    <InputGroup className="mb-2" key={index}>
                      <InputGroup.Text>{index + 1}</InputGroup.Text>
                      <Form.Control
                        placeholder="Email address"
                        type="email"
                        value={participant}
                        onChange={(e) => handleParticipantChange(index, e)}
                        disabled={!isEditMode && !isNewMode}
                      />
                      {(isEditMode || isNewMode) && (
                        <Button
                          variant="outline-danger"
                          onClick={() => removeParticipant(index)}
                        >
                          Remove
                        </Button>
                      )}
                      {!isEditMode && !isNewMode && (
                        <Button
                          variant="success"
                          onClick={() =>
                            navigate(`/entries/${studyId}/${participant}`)
                          }
                        >
                          Entries
                        </Button>
                      )}
                    </InputGroup>
                  ))}
                  {(isEditMode || isNewMode) && (
                    <Col xs="6">
                      <Button
                        className="mb-3"
                        variant="outline-secondary"
                        onClick={addParticipant}
                      >
                        Add Participant
                      </Button>
                    </Col>
                  )}
                </Row>

                {/* Form operations */}
                <Row className="mb-3 mt-3">
                  {(isEditMode || isNewMode) && (
                    <Col xs="4">
                      <Button className="w-100" variant="primary" type="submit">
                        {isNewMode ? `Create study` : `Save changes`}
                      </Button>
                    </Col>
                  )}
                  {isEditMode || isNewMode || (
                    <Col xs="4">
                      <Link to={`/studies/${studyId}/edit`}>
                        <Button className="w-100">Edit</Button>
                      </Link>
                    </Col>
                  )}
                  {studyId && (
                    <Col xs="4">
                      <DeleteButtonComponent
                        onDelete={handleDelete}
                        itemId={studyId}
                        description={`Are you sure you want to delete study: ${formData.name}? All journal entries will be lost.`}
                        redirect="/studies"
                      />
                    </Col>
                  )}
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StudyForm;
