import React, { useState, FormEvent, useEffect } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { components } from "../services/apiService";
import { apiClient } from "../services/api";
import { Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import DeleteButtonComponent from "../components/DeleteButton";
import NotFoundPage from "../404Page";
import { useFiefTokenInfo } from "@fief/fief/build/esm/react";
import InteractionForm from "../components/Interaction";

type Connections = {
  [key: string]: components["schemas"]["ConnectionProperties"];
};

const EntryForm: React.FC = () => {
  const { study: studyId } = useParams<"study">();
  const { participant: participantId } = useParams<"participant">();
  const { week: weekNumberStr } = useParams<"week">();
  const [week, setWeek] = useState<number | null>(null);
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [userConnections, setUserConnections] = useState<Connections>({});
  const tokenInfo = useFiefTokenInfo();
  const [study, setStudy] = useState<Study | null>(null);

  // Boolean to determine if we are in edit mode.
  const isEditMode = location.pathname.endsWith("/edit");
  const isNewMode = location.pathname.endsWith("/new");

  type JournalEntry = components["schemas"]["JournalEntry"];
  type JournalEntryContent = components["schemas"]["JournalEntryContent"];
  type Study = components["schemas"]["Study"];

  // Define state for the form fields
  const [journalEntry, setJournalEntry] = useState<JournalEntry>({
    _id: undefined,
    interactions: [],
    study: studyId || "",
    participant: participantId || "",
    week: week || 0,
    modified: undefined,
    created: undefined,
  });

  type Interaction = components["schemas"]["Interaction"];
  const [savedInteractions, setSavedInteractions] = useState<Interaction[]>([]);

  // Get the navigate function from the useNavigate hook
  const navigate = useNavigate();

  useEffect(() => {
    // Set week number if week changes in the URL
    if (weekNumberStr) {
      setWeek(parseInt(weekNumberStr));
    }
  }, [weekNumberStr]);

  useEffect(() => {
    // Handle unsaved changes when user tries to leave the page
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes, are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    // Fetch study details when component mounts
    const fetchStudy = async () => {
      if (studyId) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/studies/{study}",
            {
              params: { path: { study: studyId } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setStudy(data);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load study with id: ${studyId}`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching the data");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchStudy();
  }, [studyId, tokenInfo?.access_token]);

  useEffect(() => {
    // Fetch entry details when component mounts
    const fetchEntries = async () => {
      if (studyId && participantId && week && !isNewMode) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/entries/{study}/{participant}/{week}",
            {
              params: {
                path: {
                  study: studyId,
                  participant: participantId,
                  week: week,
                },
              },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            if (isNewMode) {
              navigate(`/entries/${studyId}/${participantId}/${week}/edit`);
            }
            setJournalEntry(data);
            setSavedInteractions(data.interactions);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load entry for study '${studyId}', participant '${participantId}', week '${week}'`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching the data");
        }
      }
      setLoading(false);
    };
    fetchEntries();
  }, [
    studyId,
    participantId,
    week,
    isNewMode,
    navigate,
    tokenInfo?.access_token,
  ]);

  useEffect(() => {
    const fetchConnections = async () => {
      if (studyId && participantId) {
        try {
          const { data, error, response } = await apiClient.GET(
            "/connections/{study}/{participant}",
            {
              params: { path: { study: studyId, participant: participantId } },
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            },
          );
          if (data) {
            setUserConnections(data);
          } else if (error) {
            setError(
              `${response.statusText}: Failed to load connections for study '${studyId}', participant '${participantId}'`,
            );
          }
        } catch (err) {
          setError("An error occurred while fetching connections");
        }
      }
    };
    fetchConnections();
  }, [studyId, participantId, tokenInfo?.access_token]);

  // Function to handle adding new interaction
  const addInteraction = () => {
    setJournalEntry({
      ...journalEntry,
      interactions: [
        ...journalEntry.interactions,
        {
          answers: (study?.questions || []).map((question) => {
            if (question.type === "slider") {
              return 50;
            }
            if (question.type === "duration") {
              return 15;
            }
            return "";
          }) as (string | number)[],
          connections: {},
        },
      ],
    });
  };

  //Function to handle deleting interaction
  const deleteInteraction = (index: number) => {
    const newInteractions = [...journalEntry.interactions];
    newInteractions.splice(index, 1);
    setJournalEntry({
      ...journalEntry,
      interactions: newInteractions,
    });
    setHasUnsavedChanges(
      JSON.stringify(newInteractions) !== JSON.stringify(savedInteractions),
    );
  };

  const handleDelete = async (itemId: string) => {
    if (!isNewMode && studyId && participantId && week) {
      try {
        const { error, response } = await apiClient.DELETE(
          "/entries/{study}/{participant}/{week}",
          {
            params: {
              path: {
                study: studyId,
                participant: participantId,
                week: week,
              },
            },
            headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
          },
        );
        if (error) {
          setError(
            `${response.statusText}: Failed to delete entry for study '${studyId}', participant '${participantId}', week '${week}'`,
          );
        } else {
          navigate(`/entries/${studyId}/${participantId}`);
        }
      } catch (err) {
        setError(
          `An error occurred while deleting entry for study '${studyId}', participant '${participantId}', week '${week}'`,
        );
      }
    }
  };

  function extractWordsStartingWithAt(inputString: string): string[] {
    const regex = /\B@\w+/g;
    const matches = inputString.match(regex) || [];
    return matches.map((match) => match.substring(1)); // Remove the '@' by taking a substring starting at the second character
  }

  // Update connections for this week
  const updateConnections = (interaction: Interaction) => {
    let handles: string[] = [];

    // Cycle over answers, for each answer retrive the question type, if question type is text_handle then update the handles
    interaction.answers.forEach((answer, index) => {
      const question = study?.questions[index]!;
      if (question.type === "text_handle" && typeof answer === "string") {
        handles.push(...extractWordsStartingWithAt(answer));
      }
    });
    let updatedConnections = { ...interaction.connections };

    // Remove handles from formData.connections that do not have a matching key
    for (const handle in updatedConnections) {
      if (!handles.includes(handle)) {
        delete updatedConnections[handle];
      }
    }

    // Add handles that do not appear in formData.connections
    for (const handle of handles) {
      if (!(handle in updatedConnections)) {
        const relation = userConnections[handle]?.type;
        updatedConnections[handle] = relation || "";
      }
    }

    return updatedConnections;
  };

  // Handle answer change
  const handleAnswerChange = (
    interactionIndex: number,
    questionIndex: number,
    value: string | number,
    parse: boolean = true,
  ) => {
    if (parse) {
      const question = study?.questions[questionIndex]!;
      if (question.type === "duration") {
        if (!isNaN(Number(value))) {
          value = Number(value);
        } else {
          // Convert duration string to number of minutes
          const [hours, minutes] = (value as string).split(":");
          value = Number(hours) * 60 + Number(minutes);
        }
      }
      if (question.type === "slider") {
        value = Number(value);
      }
    }

    const newInteraction = structuredClone(
      journalEntry.interactions[interactionIndex]!,
    );
    newInteraction!.answers[questionIndex] = value;
    newInteraction!.connections = updateConnections(newInteraction);

    const newInteractions = structuredClone(journalEntry.interactions);
    newInteractions[interactionIndex] = newInteraction;

    setHasUnsavedChanges(
      JSON.stringify(newInteractions) !== JSON.stringify(savedInteractions),
    );

    setJournalEntry({ ...journalEntry, interactions: newInteractions });
  };

  const handleConnectionChange = (
    interactionIndex: number,
    connectionKey: string,
    value: string,
  ) => {
    const newInteractions = structuredClone(journalEntry.interactions);
    newInteractions[interactionIndex]!.connections[connectionKey] = value;

    setHasUnsavedChanges(
      JSON.stringify(newInteractions) !== JSON.stringify(savedInteractions),
    );
    setJournalEntry({
      ...journalEntry,
      interactions: newInteractions,
    });
  };

  // Handle form submission
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    // Sanitize connections by trimming whitespaces from values
    const sanitizedInteractions = journalEntry.interactions.map(
      (interaction) => {
        const sanitizedConnections = Object.fromEntries(
          Object.entries(interaction.connections).map(([key, value]) => [
            key,
            value.trim(),
          ]),
        );
        return { ...interaction, connections: sanitizedConnections };
      },
    );

    const body: JournalEntryContent = {
      interactions: sanitizedInteractions,
    };

    try {
      if (studyId && participantId && week) {
        const { data, error, response } = isNewMode
          ? await apiClient.POST("/entries/{study}/{participant}/{week}", {
              params: {
                path: {
                  study: studyId,
                  participant: participantId,
                  week: week,
                },
              },
              body: body,
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            })
          : await apiClient.PUT("/entries/{study}/{participant}/{week}", {
              params: {
                path: {
                  study: studyId,
                  participant: participantId,
                  week: week,
                },
              },
              body: body,
              headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
            });
        if (data) {
          setHasUnsavedChanges(false);
          navigate(`/entries/${studyId}/${participantId}`);
        } else if (error) {
          const errorString = `${response.statusText}: Failed to save entry for study '${studyId}', participant '${participantId}', week '${week}'`;
          setError(errorString);
          let newErrorDetails: string[] = [];

          if (response.status === 422 && typeof error.detail === "object") {
            for (let detail of error.detail) {
              if (detail.loc.length) {
                const detailString = `Field '${detail.loc.join(".")}': ${
                  detail.msg
                }`;
                newErrorDetails.push(detailString);
              }
            }
          } else if (typeof error.detail === "string") {
            newErrorDetails.push(error.detail);
          }
          setErrorDetails(newErrorDetails);
        }
      }
    } catch (err) {
      const errorString = `An error occurred while saving entry for study '${studyId}', participant '${participantId}', week '${week}'`;
      setError(errorString);
    }
  };

  if (!(studyId && participantId && week)) {
    return <NotFoundPage />;
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col lg="10" md="12">
          {error ? (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{error}</p>
              {errorDetails.length > 0 && (
                <ul>
                  {errorDetails.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              )}
            </Alert>
          ) : (
            <>
              {loading && (
                <Alert variant="info">
                  <Alert.Heading>Loading</Alert.Heading>
                  <p>Please wait...</p>
                </Alert>
              )}
              <Row className="justify-content-md-center">
                <h1 style={{ textAlign: "center" }}>Week {week}</h1>
              </Row>
              <Form onSubmit={handleSubmit}>
                {/* Display interactions */}
                {journalEntry.interactions.map((interaction, index) => (
                  <Row key={index} className="mt-3">
                    <Row>
                      <Col xs="8">
                        <h2>Interaction {index + 1}</h2>
                      </Col>
                      {(isEditMode || isNewMode) && (
                        <>
                          <Col xs="4">
                            <Button
                              className="w-100"
                              variant="outline-danger"
                              onClick={() => deleteInteraction(index)}
                              tabIndex={-1}
                            >
                              Delete interaction
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                    <InteractionForm
                      questions={study?.questions || []}
                      interactionIndex={index}
                      interaction={interaction}
                      handleAnswerChange={handleAnswerChange}
                      handleConnectionChange={handleConnectionChange}
                      isEditMode={isEditMode}
                      isNewMode={isNewMode}
                    />
                  </Row>
                ))}

                <Row className="mt-3">
                  {(isEditMode || isNewMode) && (
                    <Col xs="6">
                      <Button
                        className="w-100"
                        variant="outline-primary"
                        onClick={addInteraction}
                      >
                        Add interaction
                      </Button>
                    </Col>
                  )}
                </Row>

                <Row className="mb-3 mt-3">
                  {(isEditMode ||
                    (isNewMode && journalEntry.interactions.length > 0)) && (
                    <Col xs="4">
                      <Button
                        className="w-100"
                        variant={hasUnsavedChanges ? "primary" : "secondary"}
                        type="submit"
                        disabled={!hasUnsavedChanges}
                      >
                        {isNewMode ? `Create entry` : `Save changes`}
                      </Button>
                    </Col>
                  )}
                  {isEditMode || isNewMode || (
                    <Col xs="4">
                      <Link
                        to={`/entries/${studyId}/${participantId}/${weekNumberStr}/edit`}
                      >
                        <Button className="w-100">Edit</Button>
                      </Link>
                    </Col>
                  )}
                  {journalEntry._id && (
                    <Col xs="4">
                      <DeleteButtonComponent
                        onDelete={handleDelete}
                        itemId=""
                        description={`Are you sure you want to delete journal entry for week ${weekNumberStr}?`}
                        redirect={`/entries/${studyId}/${participantId}`}
                      />
                    </Col>
                  )}
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EntryForm;
