import { useFiefAuth } from "@fief/fief/react";
import { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Callback: React.FunctionComponent = () => {
  const fiefAuth = useFiefAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fiefAuth
      .authCallback(
        `${window.location.protocol}//${window.location.host}/callback`,
      )
      .then(() => {
        navigate("/");
      });
  }, [fiefAuth, navigate]);

  return (
    <Row className="mt-5">
      <Col></Col>
      <Col xs={6}>
        <Alert variant="info">
          <Alert.Heading>Authentication in progress</Alert.Heading>
          <p>You will be redirect shortly</p>
        </Alert>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default Callback;
