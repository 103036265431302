// StudyList.tsx
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { Alert, ListGroup, Row, Col, Container } from "react-bootstrap";
import { apiClient } from "../services/api";
import { components } from "../services/apiService";
import { useFiefTokenInfo, useFiefUserinfo } from "@fief/fief/build/esm/react";

const StudyList: React.FC = () => {
  const [studyList, setStudyList] = useState<
    components["schemas"]["StudyProperty"][]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const tokenInfo = useFiefTokenInfo();
  const userinfo = useFiefUserinfo();
  // Fetch the list of studies when component mounts
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const { data, error, response } = await apiClient.GET("/studies/", {
          headers: { Authorization: `Bearer ${tokenInfo?.access_token}` },
        });
        if (data) {
          setStudyList(data);
        } else if (error) {
          setError(`${response.statusText}: Failed to load studies`);
        }
      } catch (err) {
        setError("An error occurred while fetching the data");
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [tokenInfo?.access_token]);

  return studyList.length === 1 && studyList[0]?.role === "participant" ? (
    <Navigate to={`/entries/${studyList[0].id}/${userinfo?.email}/`} replace />
  ) : (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col lg="6">
          <div>
            {error && (
              <Alert
                variant="danger"
                onClose={() => setError(null)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>{error}</p>
              </Alert>
            )}

            {loading ? <div>Loading...</div> : ""}

            <ListGroup>
              {studyList.map((study) => (
                <Link
                  to={
                    study.role === "manager"
                      ? `/studies/${study.id}`
                      : `/entries/${study.id}/${userinfo?.email}/`
                  }
                  className="btn"
                  key={study.id}
                >
                  <ListGroup.Item key={study.id} action as="div">
                    {study.name}
                  </ListGroup.Item>
                </Link>
              ))}
            </ListGroup>

            <Link to="/studies/new" className="btn btn-primary mt-3 w-100">
              Create new study
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StudyList;
