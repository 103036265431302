import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-body-tertiary mt-auto py-3" data-bs-theme="dark">
      <Container>
        <Row>
          <Col className="text-center text-secondary">
            Study Abroad Journal | bondibrown {currentYear} &#169; | Frontend{" "}
            {process.env.REACT_APP_VERSION}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
