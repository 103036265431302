import {
  useFiefAuth,
  useFiefIsAuthenticated,
  useFiefUserinfo,
} from "@fief/fief/react";

import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavBar: React.FC = () => {
  const fiefAuth = useFiefAuth();
  const isAuthenticated = useFiefIsAuthenticated();
  const userinfo = useFiefUserinfo();

  const login = useCallback(() => {
    fiefAuth.redirectToLogin(
      `${window.location.protocol}//${window.location.host}/callback`,
    );
  }, [fiefAuth]);

  const logout = useCallback(() => {
    fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
  }, [fiefAuth]);

  const [study, setStudy] = useState("");
  const [participant, setParticipant] = useState("");

  const location = useLocation();

  useEffect(() => {
    // Extract the study and participant from the URL
    const match = location.pathname.match(/^\/entries\/([^/]+)\/([^/]+)/);

    if (match) {
      setStudy(match[1]!);
      setParticipant(match[2]!);
    } else {
      setStudy("");
      setParticipant("");
    }
  }, [location]);

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary justify-content-between"
      data-bs-theme="dark"
    >
      <Container>
        <Navbar.Brand href="/">SAJ</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" activeKey={location.pathname}>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/studies">Studies</Nav.Link>
            {study && participant && (
              <Nav.Link href={`/entries/${study}/${participant}`}>
                Dashboard
              </Nav.Link>
            )}
          </Nav>

          <Nav>
            {!isAuthenticated && (
              <Nav.Link onClick={() => login()}>Login</Nav.Link>
            )}
            {isAuthenticated && userinfo && (
              <>
                <Navbar.Text>{userinfo.email}</Navbar.Text>
                <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
