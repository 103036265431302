import React from "react";
import { components } from "../services/apiService";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import "./Interaction.css";

type Interaction = components["schemas"]["Interaction"];
type Question = components["schemas"]["Question"];

type InteractionFormProps = {
  questions: Question[];
  interactionIndex: number;
  interaction: Interaction;
  isEditMode: boolean;
  isNewMode: boolean;
  handleAnswerChange: (
    interactionIndex: number,
    questionIndex: number,
    value: string | number,
    parse: boolean,
  ) => void;
  handleConnectionChange: (
    interactionIndex: number,
    connectionKey: string,
    value: string,
  ) => void;
};

const InteractionForm: React.FC<InteractionFormProps> = (
  props: InteractionFormProps,
) => {
  return (
    <>
      <Row className="answers">
        {/* Iterate over questions and answers, and build form according to question type */}
        {props.questions.map((question, questionIndex) => {
          if (question.type === "text" || question.type === "text_handle") {
            return (
              <Form.Group
                controlId={`interaction-${props.interactionIndex}-question-${questionIndex}`}
                key={questionIndex}
                className="form-group"
              >
                <Form.Label>{question.prompt}</Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  value={props.interaction.answers[questionIndex]}
                  onChange={(e) => {
                    e.preventDefault();
                    props.handleAnswerChange(
                      props.interactionIndex,
                      questionIndex,
                      e.target.value,
                      true,
                    );
                  }}
                  placeholder={question.hint}
                  disabled={!(props.isEditMode || props.isNewMode)}
                />
              </Form.Group>
            );
          }
          if (question.type === "duration") {
            return (
              <Form.Group
                controlId={`interaction-${props.interactionIndex}-question-${questionIndex}`}
                key={questionIndex}
                className="form-group"
              >
                <Form.Label>{question.prompt}</Form.Label>
                <Form.Control
                  type="string"
                  required
                  value={
                    (typeof props.interaction.answers[questionIndex] ===
                      "number" &&
                      `${Math.floor(
                        Number(props.interaction.answers[questionIndex]) / 60,
                      )
                        .toString()
                        .padStart(2, "0")}:${(
                        Number(props.interaction.answers[questionIndex]) % 60
                      )
                        .toString()
                        .padStart(2, "0")}`) ||
                    props.interaction.answers[questionIndex]
                  }
                  onChange={(e) => {
                    props.handleAnswerChange(
                      props.interactionIndex,
                      questionIndex,
                      e.target.value,
                      false,
                    );
                  }}
                  onBlur={(e) => {
                    props.handleAnswerChange(
                      props.interactionIndex,
                      questionIndex,
                      e.target.value,
                      true,
                    );
                  }}
                  placeholder="hours:minutes"
                  disabled={!(props.isEditMode || props.isNewMode)}
                />
              </Form.Group>
            );
          }
          if (question.type === "slider") {
            return (
              <Form.Group key={questionIndex} className="form-group">
                <Form.Label>{question.prompt}</Form.Label>
                <InputGroup className="input-group-flex">
                  <InputGroup.Text>
                    {question.hint?.split(":")[0]}
                  </InputGroup.Text>

                  <Form.Range
                    className="form-range"
                    required
                    value={props.interaction.answers[questionIndex] || ""}
                    onChange={(e) => {
                      props.handleAnswerChange(
                        props.interactionIndex,
                        questionIndex,
                        e.target.value,
                        true,
                      );
                    }}
                    disabled={!(props.isEditMode || props.isNewMode)}
                  />

                  <InputGroup.Text>
                    {question.hint?.split(":")[1]}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            );
          }
          return <></>;
        })}
      </Row>
      <Row className="connections">
        {Object.keys(props.interaction.connections).length > 0 && (
          <h4>People</h4>
        )}
        {Object.entries(props.interaction.connections).map(([key, value]) => (
          <Col lg="6" md="12" key={key}>
            <Form.Group
              key={key}
              controlId={`connection-${key}`}
              className="form-group"
            >
              <Form.Label>{key}</Form.Label>
              <Form.Control
                type="text"
                name={`connections.${key}`}
                value={value}
                required
                onChange={(e) => {
                  e.preventDefault();
                  props.handleConnectionChange(
                    props.interactionIndex,
                    key,
                    e.target.value,
                  );
                }}
                placeholder={`Ex: classmate, service encounter, TA, neighbor`}
                disabled={!(props.isEditMode || props.isNewMode)}
              />
            </Form.Group>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default InteractionForm;
