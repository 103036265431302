// DeleteButtonComponent.tsx
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ConfirmationModal from "./ConfirmationModal";

interface DeleteButtonProps {
  onDelete: (itemId: string) => Promise<void>;
  itemId: string;
  description: string;
  redirect: string;
}

const DeleteButtonComponent: React.FC<DeleteButtonProps> = ({
  onDelete,
  itemId,
  description,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = async () => {
    await onDelete(itemId);
  };

  return (
    <>
      <Button
        className="w-100"
        variant="danger"
        onClick={() => setShowConfirmation(true)}
      >
        Delete
      </Button>

      <ConfirmationModal
        description={description}
        showModal={showConfirmation}
        onConfirm={handleDelete}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default DeleteButtonComponent;
